@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #1d4ed8;
  --accent-purple: #8b5cf6;
  --accent-indigo: #6366f1;
  --accent-cyan: #06b6d4;
  --accent-pink: #ec4899;
  --accent-orange: #f97316;
  --accent-emerald: #10b981;
}

@layer components {
  .gradient-text {
    background-image: linear-gradient(
      90deg,
      var(--primary-color),
      var(--accent-purple),
      var(--accent-indigo)
    );
    background-clip: text;
    color: transparent; /* Esto permite ver el gradiente */
    -webkit-background-clip: text; /* Compatibilidad con navegadores WebKit */
  }

  .gradient-bg {
    @apply bg-gradient-to-r from-primary-500 via-accent-purple to-accent-indigo;
  }

  .card-hover {
    @apply transition-all duration-300 hover:shadow-xl hover:scale-105 hover:z-10;
  }

  .glass-effect {
    @apply backdrop-blur-lg bg-white/80 shadow-lg;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    135deg,
    rgba(29, 78, 216, 0.05) 0%,
    rgba(139, 92, 246, 0.05) 100%
  );
}

.hero-gradient {
  background: linear-gradient(
    135deg,
    rgba(29, 78, 216, 0.1) 0%,
    rgba(139, 92, 246, 0.1) 100%
  );
}

.card-gradient {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.95) 100%
  );
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.glass-card {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.07);
}

.gradient-border {
  position: relative;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--primary-color), var(--accent-purple))
      border-box;
  border: 2px solid transparent;
}

.feature-card {
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(29, 78, 216, 0.1),
    rgba(139, 92, 246, 0.1)
  );
  z-index: 0;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.feature-card:hover::before {
  opacity: 1;
}

.animated-gradient {
  background: linear-gradient(
    270deg,
    var(--primary-color),
    var(--accent-purple),
    var(--accent-indigo),
    var(--primary-color)
  );
  background-size: 300% 300%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hover-lift {
  transition: transform 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

html {
  scroll-behavior: smooth;
}
