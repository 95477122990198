.confirmation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.confirmation-container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.confirmation-container h1 {
  color: #4caf50;
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: 700;
}

.confirmation-container p {
  color: #555555;
  font-size: 18px;
  margin-bottom: 20px;
}

.confirmation-container a {
  display: inline-block;
  padding: 12px 24px;
  color: #ffffff;
  background-color: #4caf50;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.confirmation-container a:hover {
  background-color: #45a049;
}
