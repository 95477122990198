.navlink-container {
  display: flex;
  align-items: center;
  color: black;
  margin-left: 0.75rem;
  text-decoration: none;
  fill: black;
  font-weight: normal;
}

.navlink-container:hover {
  color: rgb(37 99 235);
  fill: rgb(37 99 235);
}

@media (max-width: 1024px) {
  .navlink-container {
    color: #fff;
    fill: #fff;
  }
  .nav-Container {
    padding-left: 0px !important;
    height: 35vh;
    justify-content: space-around;
  }
}

.navbar-Mobile {
  width: 100vw;
  height: 11vh;
  position: fixed;
  z-index: 4;
  background-color: white;
  overflow: hidden;
  top: 0;
  left: 0;
  transition:
    height,
    background-color 0.7s ease;
}

.navbar-Mobile.is-active {
  width: 100vw;
  height: 50vh;
  background: black;
  transition:
    height,
    background-color 0.5s ease;
}

.hamburger {
  position: relative;
  z-index: 4;
  outline: none;
}

.hamburger:focus .button-Connect:focus {
  outline: none;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
  background-color: white;
}

.button-Connect {
  padding: 0.5rem;
  border-radius: 10px;
  width: 60%;
  color: #6f6f6f;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

@media (max-width: 1024px) and (min-width: 640px) {
  .button-Connect {
    font-size: 1.5vw;
  }
}

@media (max-width: 640px) and (min-width: 425px) {
  .nav-Container div {
    width: 30%;
  }
  .button-Connect {
    width: 100%;
  }
  .navbar-Mobile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .navbar-Mobile.is-active {
    display: flex;
    align-items: flex-end;
  }
}

@media (max-width: 425px) and (min-width: 320px) {
  .nav-Container {
    height: 35vh;
    justify-content: space-around;
  }
  .nav-Container div {
    width: 40%;
  }
  .button-Connect {
    width: 100%;
    font-size: 3.5vw;
  }
}
