.contract-Container {
  font-family: Arial, sans-serif;
  padding: 1.5rem;
  margin: 0 auto;
  width: 90%;
}

.form-section {
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: self-start;
  width: 100%;
  flex-direction: column;
}

.form-group label {
  width: inherit;
  font-size: 15px;
  display: block;
  font-weight: bold;
  white-space: nowrap;
  color: #6f6f6f;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.form-group input {
  width: inherit;
  height: 3.5vh;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 150px;
}

.form-group select {
  width: 75%;
  height: 3.5vh;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 150px;
  padding: 0;
}

.dynamic-input {
  width: auto;
  margin: 5px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

.contract-section {
  padding: 4rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
}

.contract-section div {
  background-color: white;
  padding: 4rem 6rem;
}

.contract-content {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
}

.contract-content .bold-text {
  font-weight: 700;
}

.contract-content .variable-highlight {
  color: #2a7ae2;
}

.btn-after-before {
  width: 30%;
  cursor: pointer;
  border: none;
  color: white;
  background-color: rgb(37 99 235);
  border-radius: 5px;
}

.btn-save {
  width: 30%;
  cursor: pointer;
  border: none;
  color: rgb(23, 43, 133);
  background-color: #b0b0b0;
  border-radius: 5px;
  opacity: 50%;
  cursor: not-allowed;
}
.blurred {
  filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
  user-select: none;
}

.payment-Redirection {
  color: white;
}

.controlator {
  min-height: 30vh;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .form-group label {
    font-size: 1.5vw !important;
  }
  .contract-section div {
    background-color: white;
    padding: 4rem 5rem;
  }
  .Buttons-Container {
    width: 50% !important;
  }
}

@media (max-width: 768px) and (min-width: 426px) {
  .form-group label {
    font-size: 1.8vw !important;
  }
  .form-group input {
    font-size: 1.5vw !important;
  }
  .contract-section div {
    background-color: white;
    padding: 4rem 5rem;
  }
  .Buttons-Container {
    width: 50% !important;
  }
}

@media (max-width: 640px) {
  .form-section {
    flex-direction: column;
    justify-content: flex-start;
  }
  .form-group label {
    white-space: wrap;
  }
  .form-group {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .form-group input {
    width: 100%;
  }
  .header-Container {
    padding: 0.5rem;
    padding-top: 0;
    padding-bottom: 2rem;
    margin-top: 20% !important;
  }
  .contract-Container {
    padding: 0.5rem;
    padding-bottom: 2rem;
  }
  .Buttons-Container {
    width: 100%;
  }
  .contract-content {
    font-size: 12px;
  }
  .contract-section div {
    padding: 1rem 1rem;
  }
  .contract-section {
    padding: 1rem;
  }
  .controller-Container {
    justify-content: space-between;
  }
  .controlator {
    margin-bottom: 1rem;
  }
  .section-edit-clauses {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 25vh;
    justify-content: space-evenly;
    margin-bottom: 20%;
  }
  .clauses-label {
    width: 100%;
  }
  .button-edit-clauses {
    width: 60%;
  }
  .payment-Container {
    height: 25vh;
    position: relative;
    margin-bottom: 0;
  }
  .payment_Logo_L {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 35vw;
  }
  .payment_Logo_R {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10vw;
    height: 12vh;
    z-index: 1;
  }
  .payment-Container section {
    align-items: center;
    width: 100%;
    z-index: 3;
  }
  .payment-Redirection {
    color: white;
    text-align: center;
    width: 100%;
  }
  .dynamic-input {
    margin: 0;
  }
}

@media (max-width: 425px) {
  .card-Controll-Title {
    height: 7vh !important;
  }
}

@media (max-width: 375px) {
  .payment_Logo_R {
    height: 6vh;
  }
}
