.payment-Container.successful {
  margin-top: 5vw;
}

@media (max-width: 640px) {
  .payment-Container.successful {
    margin: 10.5vw 0vw 5vw 0vw;
  }
}

@media (max-width: 425px) {
  .payment-Container.successful {
    margin: 15.5vw 0vw 5vw 0vw;
  }
}

.contract-content {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
}
